import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    token2: localStorage.getItem('access_token2') || null,
    tokenLocal: localStorage.getItem('access_token_local') || null,
    startOrder: '',
    cart: [],
    
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    }
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
      
    },
    destroyToken(state) {
      state.token = null
    },
    startorder(state, client) {
      state.startOrder = client;
    },
    cancelorder(state) {
      state.startOrder = '';
    },
    addToCart(state, product) {
      console.log('adding to cart');
        let itemInCartIndex = state.cart.findIndex(item => item.id == product.id);
        if(itemInCartIndex !== -1) {
          console.log('appending to item within cart');
          state.cart[itemInCartIndex].quantity++;
          console.log("cart quantity: " + state.cart[itemInCartIndex].quantity);
          console.log(state.cart);
          return;
        }
        product.quantity = 1;
        state.cart.push(product);
        console.log(state.cart);
    },
    remFromCart(state, product) {
      console.log('Removing from cart');
      let index = state.cart.findIndex(item => item.id == product.id);
      if(index !== -1) {
        if(product.quantity >= 1) {
          console.log("will still remove");
          state.cart[index].quantity = product.quantity;
        return 
        }
        
      }
    }
  },
  actions: {
    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.post('/login', {
            
              email: credentials.email,
              password: credentials.password,
          
          }).then(response2 => {
            axios.get('/api/user').then(response4 => {
              const token = response4.data.email;
              const isadminToken = response4.data.isadmin;
              const localToken = response4.data.role;
              localStorage.setItem('access_token', token);
              localStorage.setItem('access_token2', isadminToken);
              localStorage.setItem('access_token_local', localToken);
              resolve(response);
              
              console.log(response4);
            });
          }).catch(error => {
            console.log(error)
            reject(error)
          });
        });
      })
    },
    destroyToken(context) {
      if(context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.post('/logout')
            .then(response => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('access_token2');
              localStorage.removeItem('access_token_local');
              context.commit('destroyToken')
              resolve(response)
              // console.log(response);
            })
            .catch(errpr => {
              localStorage.removeItem('access_token')
              context.commit('destroyToken')
              reject(error)
            })
        })
      }
    },
  },
  modules: {
  }
})
