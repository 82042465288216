<template>
  <div class="wrapper">
    <div v-if="this.$route.name !== 'login'" class="leftside-menu" style="background: linear-gradient(
135deg,#5c7938 0,#585e1f 60%);background-color:red;">

        <!-- LOGO 
        <a href="index.html" class="logo text-center logo-light">
            <span class="logo-lg">
                <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fc%2Fce%2FEmojione_1F331.svg%2F768px-Emojione_1F331.svg.png&f=1&nofb=1" alt="" height="16">
            </span>
            <span class="logo-sm">
                <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fc%2Fce%2FEmojione_1F331.svg%2F768px-Emojione_1F331.svg.png&f=1&nofb=1g" alt="" height="24">
            </span>
        </a> -->

        <!-- LOGO -->
        <a href="index.html" class="logo text-center logo-dark">
            <span class="logo-lg">
                <img src="https://coderthemes.com/hyper/saas/assets/images/logo-dark.png" alt="" height="16">
            </span>
            <span class="logo-sm">
                <img src="https://coderthemes.com/hyper/saas/assets/images/logo_sm_dark.png" alt="" height="16">
            </span>
        </a>

        <div class="h-100" id="leftside-menu-container" data-simplebar>

            <!--- Sidemenu -->
            <ul class="side-nav" style="position:fixed;height:100%;">

                <li class="side-nav-title side-nav-item">Navigation</li>

                <li class="side-nav-item">
                    <router-link :to="{name: 'Home'}" class="side-nav-link">
                        <i class="uil-building"></i>
                        <span> Dashboard </span>
                    </router-link>
                </li>
                <li class="side-nav-item">
                  <router-link :to="{ name: 'catalog' }" class="side-nav-link">
                    <i class="uil-shopping-bag"></i>
                     <span> Catalog </span>
                  </router-link>
                    
                </li>
                <li class="side-nav-item">
                    <router-link :to="{name:'order-list'}" class="side-nav-link">
                        <i class="uil-shopping-cart"></i>
                        <span> Orders </span>
                    </router-link>
                </li>
                <li class="side-nav-item">
                    <router-link :to="{name:'client-list'}" class="side-nav-link">
                        <i class="uil-users-alt"></i>
                        <span> Clients </span>
                    </router-link>
                </li>
                
                <li class="side-nav-item">
                    <router-link :to="{name: 'routes'}" class="side-nav-link">
                        <i class="uil-map-marker"></i>
                        <span> Routes </span>
                    </router-link>
                </li>

                <li class="side-nav-item" style="position:absolute;bottom:100px;">
                    <button @click="logOut" class="side-nav-link" style="background-color:transparent;border:none;">
                        <i class="uil uil-signin"></i>
                        <span> Logout </span>
                    </button>
                </li>

                


          
        





            </ul>

            <!-- Help Box -->
            
            <!-- end Help Box -->
            <!-- End Sidebar -->

            <div class="clearfix"></div>

        </div>
        <!-- Sidebar -left -->

    </div>
    <div class="content-page">
      <router-view></router-view>
    </div>

    
  </div>
</template>
<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL='https://gapi.merginggrowth.com';
//axios.defaults.baseURL='http://glapi.testmachine.test:8000';
export default {
    methods: {
        logOut() {
            if(confirm("Are you sure you want to log out?")) {
                this.$store.dispatch('destroyToken')
                .then(response => {
                this.$router.push({ name: 'login' })
                });
            } 
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
    },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.leftside-menu {
    background-color:black;
}
.leftside-menu a {
    color: white;
}

.side-nav-title {
    display: none;
}
.side-nav .side-nav-link span {
    display: none;
}

.leftside-menu {
    width: 75px;
}

.content-page {
    margin-left: 70px;
}

.side-nav-item {
    padding: 8px 0;
}
.side-nav-link  i {
    font-size:6rem;
}

</style>
