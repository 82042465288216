import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import Toasted from 'vue-toasted';

import './assets/css/app2.min.css'

Vue.config.productionTip = false
Vue.use(require('vue-moment'));
Vue.use(require('moment-business-days'));
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDeSwk15TAF6owaM7HHNM8eDCSwS6DS0-Y',
    libraries: 'places',
  }
});
Vue.use(Toasted, {
  position: 'top-center',
  theme: 'bubble',
  duration: 2500,
});

function isLoggedIn() {
  return localStorage.getItem('access_token');
}
function isLoggedIn2() {
  return localStorage.getItem('access_token2');
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
