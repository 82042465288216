<template>
 <div class="content">
  <!-- Topbar Start -->
   
    <!-- end Topbar -->

    <!-- Start Content-->
    <div class="container-fluid" style="margin-top:-40px;padding-top:0px;">
        
        <!-- start page title -->
          
            
        <!-- end page title --> 

        <div class="row">
            
            <div class="col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <span class="float-end text-nowrap"><i class="uil uil-panel-add me-1"></i>$120,000</span>
                        
                        <h6 class="text-uppercase mt-0">Month Sales</h6>
                        <h2 class="my-1" id="active-users-count">$104,000</h2>
                        <p class="mb-0 text-muted">
                            <span class="text-success me-2"><span class="uil-arrow-up"></span> 5.27%</span>
                            <span class="text-nowrap">$16,000 Remaining</span>  
                        </p>
                    </div> <!-- end card-body -->
                </div> <!-- end card -->
            </div>
            <div class="col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <span class="float-end text-nowrap"><i class="uil uil-panel-add me-1"></i>$5,000</span>
                        <h6 class="text-uppercase mt-0">Today's Sales</h6>
                        <h2 class="my-1" id="active-users-count">$4,650</h2>
                        <p class="mb-0 text-muted">
                            <span class="text-success me-2"><span class="uil-arrow-up"></span> 5.27%</span>
                            <span class="text-nowrap">Average weekly</span>  
                        </p>
                    </div> <!-- end card-body -->
                </div> <!-- end card -->
            </div>
            <div class="col-lg-4 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <span class="float-end text-nowrap">
                            2 Clients<br>
                            3 Invoices

                        </span>
                        <h6 class="text-uppercase mt-0">New Accounts</h6>
                        <h2 class="my-1" id="active-users-count">$12,000</h2>
                        <p class="mb-0 text-muted">
                            <span class="text-success me-2"><span class="uil-arrow-up"></span> 5.27%</span>
                            <span class="text-nowrap">Since last month</span>  
                        </p>
                    </div> <!-- end card-body -->
                </div> <!-- end card -->
            </div> <!-- end col -->


            
        

          

            
        </div>
        <!-- end row -->

        
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        
                        
                        <h4 class="header-title mb-1">Scheduled</h4>

                        <div class="chart-content-bg">
                            <div class="row text-center">
                                <div class="col-md-6">
                                    <p class="text-muted mb-0 mt-1">Prospects</p>
                                    <h2 class="fw-normal mb-1">
                                        <span>45</span>
                                    </h2>
                                </div>
                                <div class="col-md-6">
                                    <p class="text-muted mb-0 mt-1">Clients</p>
                                    <h2 class="fw-normal mb-1">
                                        <span>30</span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                    <!-- end card body-->
                </div>
                <!-- end card -->
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        
                        
                        <h4 class="header-title mb-1">Create Routes</h4>

                        <div class="chart-content-bg">
                            <div class="row text-center">
                                <div class="col-md-6">
                                    <p class="text-muted mb-0 mt-1">Prospects</p>
                                    <h2 class="fw-normal mb-1">
                                        <span>20</span>
                                    </h2>
                                </div>
                                <div class="col-md-6">
                                    <p class="text-muted mb-0 mt-1">Clients</p>
                                    <h2 class="fw-normal mb-1">
                                        <span>14</span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                    <!-- end card body-->
                </div>
                <!-- end card -->
            </div>
        </div>

        <!-- end row-->


        
        <!-- end row-->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        
                        <h4 class="header-title mb-3">Today's Routes</h4>

                        <p><b>4</b> Routes completed out of 12</p>

                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap table-hover mb-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="text-muted font-13">Account</span>
                                            <h5 class="font-14 mt-1 fw-normal">East Village Market</h5>
                                        </td>
                                        
                                        <td>
                                            <span class="text-muted font-13">Status</span> <br>
                                            <span class="badge badge-success-lighten">Completed</span>
                                        </td>
                                        <td>
                                            <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">150 Bay St #2a, Jersey City, NJ 07302</a></h5>
                                            <span class="text-muted font-13">Due in 3 days</span>
                                        </td>
                                        <td>
                                            <span class="text-muted font-13"></span>
                                            <h5 class="font-14 mt-1 fw-normal"></h5>
                                        </td>
                                        <td>
                                            <a href="javascript: void(0);" class="action-icon"> <i class="uil uil-arrow-up-right"></i></a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="text-muted font-13">Account</span>
                                            <h5 class="font-14 mt-1 fw-normal">East Village Market</h5>
                                        </td>
                                        
                                        <td>
                                            <span class="text-muted font-13">Status</span> <br>
                                            <span class="badge badge-success-lighten">Completed</span>
                                        </td>
                                        <td>
                                            <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">150 Bay St #2a, Jersey City, NJ 07302</a></h5>
                                            <span class="text-muted font-13">Due in 3 days</span>
                                        </td>
                                        <td>
                                            <span class="text-muted font-13"></span>
                                            <h5 class="font-14 mt-1 fw-normal"></h5>
                                        </td>
                                        <td>
                                            <a href="javascript: void(0);" class="action-icon"> <i class="uil uil-arrow-up-right"></i></a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="text-muted font-13">Account</span>
                                            <h5 class="font-14 mt-1 fw-normal">East Village Market</h5>
                                        </td>
                                        
                                        <td>
                                            <span class="text-muted font-13">Status</span> <br>
                                            <span class="badge badge-warning-lighten">In-progress</span>
                                        </td>
                                        <td>
                                            <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">150 Bay St #2a, Jersey City, NJ 07302</a></h5>
                                            <span class="text-muted font-13">Due in 3 days</span>
                                        </td>
                                        <td>
                                            <span class="text-muted font-13"></span>
                                            <h5 class="font-14 mt-1 fw-normal"></h5>
                                        </td>
                                        <td>
                                            <a href="javascript: void(0);" class="action-icon"> <i class="uil uil-arrow-up-right"></i></a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="text-muted font-13">Account</span>
                                            <h5 class="font-14 mt-1 fw-normal">East Village Market</h5>
                                        </td>
                                        
                                        <td>
                                            <span class="text-muted font-13">Status</span> <br>
                                            <span class="badge badge-warning-lighten">In-progress</span>
                                        </td>
                                        <td>
                                            <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">150 Bay St #2a, Jersey City, NJ 07302</a></h5>
                                            <span class="text-muted font-13">Due in 3 days</span>
                                        </td>
                                        <td>
                                            <span class="text-muted font-13"></span>
                                            <h5 class="font-14 mt-1 fw-normal"></h5>
                                        </td>
                                        <td>
                                            <a href="javascript: void(0);" class="action-icon"> <i class="uil uil-arrow-up-right"></i></a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="text-muted font-13">Account</span>
                                            <h5 class="font-14 mt-1 fw-normal">East Village Market</h5>
                                        </td>
                                        
                                        <td>
                                            <span class="text-muted font-13">Status</span> <br>
                                            <span class="badge badge-warning-lighten">In-progress</span>
                                        </td>
                                        <td>
                                            <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">150 Bay St #2a, Jersey City, NJ 07302</a></h5>
                                            <span class="text-muted font-13">Due in 3 days</span>
                                        </td>
                                        <td>
                                            <span class="text-muted font-13"></span>
                                            <h5 class="font-14 mt-1 fw-normal"></h5>
                                        </td>
                                        <td>
                                            <a href="javascript: void(0);" class="action-icon"> <i class="uil uil-arrow-up-right"></i></a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="text-muted font-13">Account</span>
                                            <h5 class="font-14 mt-1 fw-normal">East Village Market</h5>
                                        </td>
                                        
                                        <td>
                                            <span class="text-muted font-13">Status</span> <br>
                                            <span class="badge badge-warning-lighten">In-progress</span>
                                        </td>
                                        <td>
                                            <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">150 Bay St #2a, Jersey City, NJ 07302</a></h5>
                                            <span class="text-muted font-13">Due in 3 days</span>
                                        </td>
                                        <td>
                                            <span class="text-muted font-13"></span>
                                            <h5 class="font-14 mt-1 fw-normal"></h5>
                                        </td>
                                        <td >
                                            <a href="javascript: void(0);" class="action-icon"> <i class="uil uil-arrow-up-right"></i></a>
                                            
                                        </td>
                                    </tr>
                                    
                                    
                                    
                                </tbody>
                            </table>
                        </div> <!-- end table-responsive-->

                    </div> <!-- end card body-->
                </div> <!-- end card -->
            </div>
        </div>
        
    </div> <!-- container -->

</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
